.mobile-button {
  cursor: pointer;
  padding: 13px 26px;
  background-color: #fff;
  border-radius: 15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: all 0.2s linear;
}

.mobile-button:hover {
  background-color: #9986C0;
}

.top-bg {
  position: fixed;
  transition: all 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  inset: 0;
  width: 100%;
  height: 100%;
  background: #101010;

  .inner-tab {
    padding: 8px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-navigation {
      display: flex;
      padding: 20px;
      top: 0;
      position: absolute;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .logo-2 {
        max-width: 97px;
      }
    }

    .bottom-navigation {
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      ul {
        list-style: none;

        li {
          font-size: 17px;
          line-height: 45px;
          letter-spacing: 0.2px;
          text-align: center;
          color: #fff;
          text-decoration: none !important;
        }
      }
    }
  }
}

.top-minus {
  top: -170%;
  visibility: hidden;
  z-index: -1;
}
